import * as React from "react";
import Head from "next/head";
import { FlexRow } from "@papercup-ai/crane";

export const Layout: React.FunctionComponent = props => (
  <FlexRow
    id="layout"
    background={"bg-primary"}
    dimensions={{ h: "vh-100", w: "vw-100" }}
    flex={{
      fai: "items-center",
      fjc: "justify-center"
    }}
  >
    <Head>
      <title>Origami</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </Head>
    <main>{props.children}</main>
  </FlexRow>
);
